import './bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp, InertiaLink } from '@inertiajs/inertia-vue3';
import * as Sentry from "@sentry/vue";
import { InertiaProgress } from '@inertiajs/progress';
import AdmContentBox from '@/Components/Adm/ContentBox.vue';
import AppContentBox from '@/Components/App/ContentBox.vue';

import AdmLayout from '@/Layouts/AdmLayout.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import { Head } from '@inertiajs/inertia-vue3';
import Header from '@/Components/Adm/Header.vue';
import ATable from '@/Components/Adm/Table.vue';
import ATd from '@/Components/Adm/Td.vue';
import ATh from '@/Components/Adm/Th.vue';
import TextArea from '@/Components/TextArea.vue'
import Button from '@/Components/Button.vue';
import CheckBox from '@/Components/Checkbox.vue';
import { Link } from '@inertiajs/inertia-vue3';
import Input from '@/Components/Input.vue';
import Label from '@/Components/Label.vue';
import Select from '@/Components/Select.vue';
import Datepicker from '@/Components/Datepicker.vue';
import moment from 'moment-timezone';
import can from './Plugins/can.js'; 
import ContentHeader from '@/Components/App/ContentHeader.vue'; 
import naive from 'naive-ui';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import '../css/app.css';


import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import Aura from '@primevue/themes/aura';

moment.tz.setDefault(0);

// We need to determine the actual environment by looking at the domain


const isProduction = window.location.hostname === 'app.prowi.io';
const isStaging = window.location.hostname === 'prowi-app-staging.herokuapp.com';
const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

// 'staging'|'production'|'local'
let environment = isProduction ? 'production' 
                 : isStaging ? 'staging'
                 : isLocal ? 'local'
                 : 'unknown'; // default to unknown if none match

// console.log('isProduction', isProduction);
// console.log('isStaging', isStaging);
// console.log('isLocal', isLocal);


export default createInertiaApp({
    title: (title) => `${title} - Prowi`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),

    setup({ el, app, props, plugin }) {

        const VueApp =  createApp(
            { 
                render: () => h(app, props) 
            }
        )

        /**
         * Configure Sentry for error tracking
         */
        if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
          const allowedDomains = [
            'localhost',
            '127.0.0.1',
            'app.prowi.io',
            'prowi-app-staging.herokuapp.com',
            /^\//, // for relative paths
          ];


          const debugEnabled = import.meta.env.VITE_APP_ENV !== 'production';


          // console.log('import.meta.env.VITE_APP_ENV', import.meta.env.VITE_APP_ENV);
          // console.log("Debug enabled: ", debugEnabled);

          Sentry.init({
            app: VueApp,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: environment,
            debug: environment !== 'production',

            integrations: [
              Sentry.replayIntegration(),
              // Sentry.browserTracingIntegration(),
            ],
            trackComponents: false,
          
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            // tracesSampleRate: (() => {
            //   switch (import.meta.env.VITE_APP_ENV) {
            //       case 'production':
            //           return 0.1; // 10% of transactions in production
            //       case 'staging':
            //           return 0.1; // 50% of transactions in staging
            //       default:
            //           return 0.1; // 100% of transactions in development
            //   }
            // })(),
            tracesSampleRate: 0.1,
          
            // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
            tracePropagationTargets: [
              "localhost", 
              // Include all URLs to capture traces on all routes
              /^https?:\/\/[^/]+\//,
            ],
          
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0,
          });
        } else {
          console.log('VITE_SENTRY_DSN_PUBLIC not found. Sentry will not be enabled.');
        }
        
        VueApp.config.globalProperties.$filters = {
            dateTimeFromNow(value){
                return moment.utc(value).local().fromNow();
            },
            dateTime(value) {
                return moment(value).utcOffset(0).calendar();
            },
            dateTimeFixed(value){
                return moment(value).utcOffset(0).format("DD.MM.YY");
            }, 
            formatCurrency(value, currency = 'DKK') {
                let formatter = new Intl.NumberFormat('da-DK', {
                    style: 'currency',
                    currency: currency,
                });

                return formatter.format(value)
            }, 
            largeNo(value){
                if (!value) return '0.00';
                const num = parseFloat(value);
                if (num >= 1000000000) {
                    return (num / 1000000000).toFixed(2) + 'B';
                }
                if (num >= 1000000) {
                    return (num / 1000000).toFixed(2) + 'M';
                }
                if (num >= 1000) {
                    return (num / 1000).toFixed(1).replace('.0', '') + 'K'
                }
                return num.toFixed(2); 
            }   
        }
                    
        VueApp.use(naive);

        /**
         * PrimeVue Configuration
         */

        VueApp.use(
          PrimeVue, {
            // Default theme configuration
            theme: {
                preset: Aura,
                options: {
                    prefix: 'p',
                    darkModeSelector: 'system',
                    cssLayer: false
                }
            }
         }
        );
        VueApp.directive('tooltip', Tooltip);


        VueApp.use(plugin)
            .mixin({ 
                methods: { route, can }, 
                components: {
                    InertiaLink, 
                    AdmContentBox, 
                    AdmLayout, 
                    AppLayout,
                    Head, 
                    Header, 
                    ATable, 
                    ATh, 
                    ATd, 
                    Button,
                    CheckBox,
                    Link,
                    AppContentBox,
                    TextArea,
                    Label, 
                    Input,
                    Select,
                    Datepicker,
                    ContentHeader
                } })
            .mount(el);


        return VueApp;
    },
});



InertiaProgress.init({ color: '#4B5563' });


